import axios from 'axios';

export const uploadFileInitial = async ({ files, cancelToken }) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }
    const file = files[0];
    const chunkSize = 50 * 1024 * 1024; // 50MB
    let start = 0;
    let end = chunkSize;
    let chunkCount = 0;
        const response = await axios.post(
            `${url}/user/startUpload`,
            {
                fileName: file.name,
                totalChunkCount: 7
            },  // Convert URLSearchParams to string
            {
                cancelToken,
                headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'application/x-www-form-urlencoded'
                }
            }
        );

        if (!response.data.success) {
            return {
                message: 'Something went wrong'
            }
        }
        const { uploadId, fileName, originalFileName } = await response.data.data;
        return {
            uploadId,
            fileName,
            start,
            end,
            chunkCount,
            chunkSize,
            originalFileName
        }

}

export const uploadFileMiddle = async ({ data, cancelToken }) => {
    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }


        let file = data.file, start = data.start, end = data.end;
        let chunkCount = data.chunkCount, fileName = data.fileName, uploadId = data.uploadId;
        let chunkSize = data.chunkSize
        while (start < file.size) {
            const chunk = file.slice(start, end);
            const formData = new FormData();
            formData.append('chunk', chunk);
            formData.append('chunkCount', chunkCount);
            formData.append('fileName', fileName);
            formData.append('uploadId', uploadId);
            const uploadChunk = await axios.post(`${url}/user/uploadChunk`, formData, {
                cancelToken,
                headers: {
                    Authorization: `Bearer ${token}`,
                    chunkCount: chunkCount,
                    uploadId: uploadId,
                    fileName: fileName
                }
            })                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          

            const { data } = await uploadChunk.data;
            start = end;
            end = start + chunkSize;
            chunkCount++;
            return {
                data,
                start,
                end,
                chunkCount,
                isFinal: false
            }
        }

        return {
            isFinal: true
        }
    
}

export const uploadFileFinal = async (data) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const json = {
        'fileName': data.fileName,
        'uploadId': data.uploadId,
        'partsData': data.partData,
        'fileSize': data.fileSize,
        'mimeType': data.mimeType,
        'originalFileName' : data.originalFileName,
        'parentId' : data.parentId
    }

    const finalResult = await axios.post(`${url}/user/completeUpload`, json, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    })

    return finalResult
}