import React, { useEffect, useState } from "react";
import Boxyellow from "../../../assets/images/box-yellow.png";
import Bluebox from "../../../assets/images/box-blue.png";
import Redbox from "../../../assets/images/red-box.png";
import EmailIcons from "../../../assets/images/email.png";
import "../SignStyles.css";
import { requestOtp, verifyOtp, resetResetPasswordState } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../features/auth/authSlice";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [showOTPField, setShowOTPField] = useState(false);
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState("");
  const [changePasswordState, setChangePasswordState] = useState(false);

  const [timer, setTimer] = useState(0);
  const [disableResend, setDisableResend] = useState(true); // initially disable resend OTP button

  const dispatch = useDispatch();
  const { requestOtpStatus, resetPasswordtoken, verifyOtpStatus, resetPasswordStatus } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  // Function to handle OTP request
  const handleRequestOtp = (e) => {
    e.preventDefault();
    if (email.length <= 0) {
      toast.error("Please enter an email");
      return;
    }
    const data = {
      email: email,
    };
    dispatch(requestOtp(data));
  };

  // Function to handle OTP verification
  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      otp: otp,
      token: resetPasswordtoken,
    };
    dispatch(verifyOtp(data));
  };

  // Function to handle password change
  const handleChangePassword = (e) => {
    e.preventDefault();
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; 
    if (!regex.test(password)) {
      toast.error("Password must contain at least one special character, one number and be 8 characters long!");
      return;
    }
    const data = {
      email: email,
      password: password,
      token: resetPasswordtoken,
    };
    dispatch(resetPassword(data));
  };

  // Resend OTP logic
  const handleResendOtp = (e) => {
    handleRequestOtp(e);
    setTimer(60); // reset timer to 60 seconds
    setDisableResend(true); // disable resend button until timer ends
  };

  // Countdown logic
  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setDisableResend(false); // enable resend button when timer ends
    }
    return () => clearInterval(interval);
  }, [timer]);

  // Handle OTP request status changes
  useEffect(() => {
    if (requestOtpStatus === "succeeded") {
      toast.success("OTP sent!!");
      setShowOTPField(true);
      setStep(2);
      setTimer(60); // start the 60-second countdown
      setDisableResend(true); // disable resend button initially
    } else if (requestOtpStatus === "failed") {
      toast.error("Email not available, or some issue occurred. Please try again later!");
    }
  }, [requestOtpStatus]);

  // Handle OTP verification status changes
  useEffect(() => {
    if (verifyOtpStatus === "succeeded") {
      toast.success("OTP Verified. Please enter new password now.");
      setOtp("");
      setChangePasswordState(true);
    } else if (verifyOtpStatus === "failed") {
      toast.error("Wrong OTP entered!");
    }
  }, [verifyOtpStatus]);

  // Handle password reset status changes
  useEffect(() => {
    if (resetPasswordStatus === "succeeded") {
      toast.success("Password Reset Success! Login with new password.");
      navigate("/signin");
    }
  }, [resetPasswordStatus]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(resetResetPasswordState());
    };
  }, [dispatch]);

  // Handle form submission
  const handleSubmit = (e) => {
    if (changePasswordState) {
      handleChangePassword(e);
    } else {
      if (step === 1) {
        handleRequestOtp(e);
      } else if (step === 2) {
        handleVerifyOtp(e);
      }
    }
    setOtp('')
  };

  return (
    <section className="create-account-sec p-100 position-relative">
      <div className="banner-animation-icons">
        <span className="yellow-box">
          <img src={Boxyellow} alt="" />
        </span>
        <span className="box-blue">
          <img src={Bluebox} alt="" />
        </span>
        <span className="red-box">
          <img src={Redbox} alt="" />
        </span>
      </div>

      <div className="form-sec-details position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 text-center">
              <div className="heading-head mb-5">
                <span className="sub-heading">Forgot?</span>
                <h2>Reset Password</h2>
              </div>
            </div>

            <div className="form-box-main">
              <div className="form-sec">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="mb-5 form-with-icons position-relative">
                    <label htmlFor="" className="form-label">
                      Email address <span className="error-defin">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      placeholder="Enter email address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="icons-left">
                      <img src={EmailIcons} alt="" />
                    </span>
                  </div>
                  {changePasswordState ? (
                    <>
                      <div className="mb-5 form-with-icons position-relative">
                        <label htmlFor="" className="form-label">
                          New Password<span className="error-defin">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          aria-describedby=""
                          placeholder="Enter New Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {showOTPField && (
                        <div className="mb-5 form-with-icons position-relative">
                          <label htmlFor="" className="form-label">
                            OTP<span className="error-defin">*</span>
                            {disableResend ? (
                              <span style={{float: 'right'}}>Resend after {timer} sec</span>
                            ) : (
                              <button type="button" className="btn btn-secondary" style={{float: 'right'}} onClick={handleResendOtp}>
                                Resend OTP
                              </button>
                            )}
                          </label>
                          <input
                            style={{ marginTop: '25px' }}
                            type="number"
                            className="form-control"
                            id=""
                            aria-describedby=""
                            value={otp}
                            placeholder="Enter the OTP"
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <button
                    type="submit"
                    className="btn btn-primary w-100 blue-btn"
                  >
                    {changePasswordState ? "Change Password" : step === 1 ? "Request OTP to reset password" : "Check OTP"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
